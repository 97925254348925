import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { TransitionGroup } from 'react-transition-group';
import type { ListItem, ListProps, UnstyledListProps } from '..';
import Transition, { TransitionVariant } from '../../Transition/Transition';
import { ListItemSpacing } from '../enums';

/**
 * The `unstyled` variant for `List`. Displays a list of items
 * in a Bootstrap unstyled list.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const UnstyledList = <T extends ListItem>({
  items = [],
  className,
  children,
  listItemClassName = (): null => null,
  listItemSpacing = ListItemSpacing.LG,
  onItemClick,
  useTransitions = true,
  populateReferences
}: UnstyledListProps<T> & ListProps<T>): React.ReactElement => {
  const cx = useClassNameMapper();

  // TODO add keyboard support/interactive content markup directly here instead of using the onItemClick method
  //  https://jira.dev.lithium.com/browse/LIA-84698

  /**
   * This function returns a regular html <li> element
   */
  function getStandardListItems(item: T, index: number) {
    return (
      <li
        ref={populateReferences ? element => populateReferences(element, index, item) : undefined}
        key={item?.id}
        data-testid="UnstyledList.ListItem"
        className={cx(listItemClassName(item))}
      >
        {children(item)}
      </li>
    );
  }

  /**
   ** This function returns a html <li> element enabled with a callback for click events
   */
  function getCustomListItems(item: T, index: number) {
    return (
      <li
        ref={populateReferences ? element => populateReferences(element, index, item) : undefined}
        key={item?.id}
        data-testid="UnstyledList.ListItem"
        role="presentation"
        className={cx(listItemClassName(item))}
        //  This should be here for when transitions aren't used as well but want this removed so leaving as is
        onMouseDown={onItemClick ? () => onItemClick(item) : undefined}
      >
        {children(item)}
      </li>
    );
  }

  return (
    <ul className={cx(className, 'list-unstyled', `lia-g-list-li-mb-${listItemSpacing}`)}>
      {useTransitions ? (
        <TransitionGroup component={null}>
          {items.map((item, index) => (
            <Transition key={item?.id} variant={TransitionVariant.FADE}>
              {onItemClick ? getCustomListItems(item, index) : getStandardListItems(item, index)}
            </Transition>
          ))}
        </TransitionGroup>
      ) : (
        items.map((item, index) => getStandardListItems(item, index))
      )}
    </ul>
  );
};

export default UnstyledList;
